.ToDoList {
	display: flex;
	flex-direction: column;
	align-items: center;
	.productContainer {
		display: flex;
		flex-direction: row;
        flex-wrap: wrap;
		width: 1500px;
		height: 100%;
		max-width: 1500px;

		.product {
			display: flex;
            flex-direction: column;
			flex-wrap: wrap;
			padding: 2px;
		}
	}
    .errorP {
        padding: 10px;
        font-size: 30px
    }
	.h1Main {
		text-align: center;
		font-size: 30px;
		color: red;
	}
	.h3 {
		padding: 20px;
		font-size: 1.5rem;
	}
	.allCaloriesP {
		font-size: 1.5rem;
	}
}
